<div class="grid-container" style="background-image: url('fingerprint.svg'); background-repeat: no-repeat; background-size: cover; grid-template-columns: 1fr;"  *ngIf="isSafari">
    <div class="grid-header">
        <img
            class="logo"
            src="/assets/images/logo.svg"
            title="logo"
            alt="logo"
            height="17"
        />
    </div>

    <div class="grid-main">
        <div class="main-content" style="padding: 1rem; text-align: center;">
            <p>
                Safari wordt (nog) niet ondersteund. Wij adviseren om Chrome of Edge als browser te gebruiken.
            </p>
        </div>
    </div>
</div>

<div class="grid-container" [ngClass]="{'cockpit': isCockpit}" *ngIf="!isSafari">
    <div class="grid-header">
        <div class="header">
            <img class="logo" src="/assets/images/logo.svg" title="logo" alt="logo" height="17"/>
            <div class="container">
                <nav role="navigation" aria-label="Main">
                    <ul class="main-menu" *ngIf="user">
                        <li [routerLink]="['/cockpit']" routerLinkActive="active"
                            [class.disabled]="disableCockpit()">
                            <app-dropdown-menu (changed)="onCockpitSubMenuItemClick($event)"
                                               [options]="cockpitMenuItems" [disabled]="disableCockpit()">
                                <span class="icon icon-speedometer-outline"></span>{{ "GENERAL.COCKPIT" | translate }}
                            </app-dropdown-menu>
                        </li>
                        <li
                            [routerLink]="['/customers']"
                            routerLinkActive="active"
                            (click)="clearCustomerEditing()"
                        >
                            <span class="icon icon-klant_empty"></span>{{ "GENERAL.ORGANISATIONS" | translate }}
                        </li>
                        <li *ngIf="user?.is_admin"
                            [routerLink]="['/measurements']"
                            routerLinkActive="active"
                        >
                            <span class="icon icon-thermos"></span>{{ "GENERAL.MEASUREMENTS" | translate }}
                        </li>
                        <li
                            *ngIf="!user?.is_admin"
                            routerLinkActive="active"
                        >
                            <a href="https://community.durvt.nl/resources" target="_blank" [ngClass]="{
                            'disabled': !user.is_admin && user.customers.length === 0
                            }" >
                                Online modules
                            </a>
                        </li>
                        <!-- <li>
                            <app-dropdown-menu (changed)="onCockpitSubMenuItemClick($event)"
                                               [options]="cockpitMenuItems" [disabled]="disableCockpit()">
                                <span class="icon icon-speedometer-outline"></span>Taal
                            </app-dropdown-menu>
                        </li> -->
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="grid-sidebar">
        <div class="sidebar">
            <app-logout></app-logout>
            <router-outlet name="sidebar"></router-outlet>
        </div>
    </div>
    <div class="grid-main bg-fingerprint">
        <div class="container main-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="grid-footer">
        <div>
            <a target="_blank" href="{{ 'GENERAL.TERMS_AND_CONDITIONS_LINK' | translate }}">{{ 'GENERAL.TERMS_AND_CONDITIONS' | translate }}</a>
            <a target="_blank" href="{{ 'GENERAL.PRIVACY_STATEMENT_LINK' | translate }}">{{ 'GENERAL.PRIVACY_STATEMENT' | translate }}</a>
        </div>
    </div>
</div>

<div id="modal-container" class="hidden"></div>
<div id="overlay" (click)="removeModal()" class="hidden"></div>
<app-loader class="center overlay" *ngIf="loaderService.isLoading()"></app-loader>
